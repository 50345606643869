$(document).ready(function() {
	menuSpyInit();
	headerUtil();
	$(".uk-container-expand-right").css({
		"margin-left": $(".uk-container").offset().left + "px"
	});

	var heightInfo = $(".lien-he-1 .info").outerHeight();
	var heightFormContact = $(".lien-he-1  .form-contact").outerHeight();
	$(".lien-he-1 .map").height(heightInfo - heightFormContact - 30);

	$(window).resize(function() {
		$(".uk-container-expand-right").css({
			"margin-left": $(".uk-container").offset().left + "px"
		});
		var heightInfo = $(".lien-he .info").outerHeight();
		var heightFormContact = $(".lien-he  .form-contact").outerHeight();
		$(".lien-he  .map").height(heightInfo - heightFormContact - 30);
	});

	$(".thu-vien .item a[data-fancybox]").fancybox({
		thumbs: {
			autoStart: true,
			axis: "x"
		}
	});

	$(".news-detail-page #news-detail-side-bar ").appendTo(
		$("#side-bar-wrapper")
	);
	$.fancybox.defaults.parentEl = "form";
	$("#popup_2 .img-wrapper").after($("#form-subscribe .form-wrapper"));
});

function headerUtil() {
	let zero = 0;
	$(window).scroll(function() {
		var windowScrollTop = $(window).scrollTop();

		$("header").toggleClass("hide", windowScrollTop > zero);
		zero = $(window).scrollTop();

		if (windowScrollTop > 0) {
			$("header").addClass("is-scrolled");
			$(".back-to-top").css({
				opacity: 1
			});
		} else {
			$("header").removeClass("is-scrolled");
			$(".back-to-top").css({
				opacity: 0
			});
		}
	});
}

function menuSpyInit() {
	var lavalampElm = document.querySelector(".nav-lamp");
	var positionLavalamp = function(activeElm) {
		lavalampElm.style.width = activeElm.elm.offsetWidth + "px";
		lavalampElm.style.left = activeElm.elm.offsetLeft + "px";
	};
	var elm = document.querySelector(".nav-wrapper");
	var ms = new MenuSpy(elm, {
		threshold: 56,
		callback: positionLavalamp
	});
}

const menuMapping = new MappingListener({
	selector: ".menu-wrapper",
	mobileWrapper: ".uk-offcanvas-bar",
	mobileMethod: "appendTo",
	desktopWrapper: ".logo-wrapper",
	desktopMethod: "insertAfter",
	breakpoint: 1025
}).watch();

const searchMapping = new MappingListener({
	selector: ".search-wrapper",
	mobileWrapper: ".uk-offcanvas-bar",
	mobileMethod: "appendTo",
	desktopWrapper: ".search-container",
	desktopMethod: "prependTo",
	breakpoint: 768
}).watch();
